
// modules
import ReactPlayer from "react-player";
import { OnProgressProps } from "react-player/base";
import { nanoid } from 'nanoid';


import { store } from '../../../store/store';
import { createStream } from "./createStream";
import { showNotPermanentErrorSnackBar } from '../../../actions/snackBar.actions';
import api from "../../../helpers/services/api";

// interfaces
import { PlayerState } from "../../../interfaces/PlayerState.interface";
import { StreamState } from "../../../interfaces/StreamState.interface";
import {
    ArticlePartStream,
    AudioPartStream,
    PartStreamParams,
    PartStreamVoice,
} from "../../../interfaces/article/ArticlePartStream.interface";


export const handleBuffering = (setPlayerState: React.Dispatch<React.SetStateAction<PlayerState>>) => {
    setPlayerState(state => ({
        ...state,
        isBuffering: true
    }));
}

export const handleBufferEnd = (setPlayerState: React.Dispatch<React.SetStateAction<PlayerState>>) => {
    setPlayerState(state => ({
        ...state,
        isBuffering: false
    }));
}

export const handlePlayPause = (setPlayerState: React.Dispatch<React.SetStateAction<PlayerState>>) => {
    setPlayerState(state => ({
        ...state,
        playing: !state.playing
    }));
}

export const handlePlayPauseEditor = (
    streamState: StreamState,
    playerState: PlayerState,
    setPlayerState: React.Dispatch<React.SetStateAction<PlayerState>>
) => {
    if (playerState.loadAgain) {
        if (streamState.enabled) {
            setPlayerState(state => ({
                ...state,
                url: '',
                playing: true,
                isBuffering: true
            }));

            createStream(streamState).then(async articlePart => {
                const voice: PartStreamVoice = {
                    id: articlePart.voice.id,
                    language: articlePart.language.code
                }

                const params: PartStreamParams = {
                    emotionStyle: articlePart.emotion.emotionStyle || null,
                    emotionDegree: articlePart.emotion.emotionDegree || null
                }

                const textSection: ArticlePartStream = {
                    id: articlePart.id,
                    text: articlePart.content,
                    voice,
                    languageId: articlePart.language.id,
                    params,
                    prosodyPitch: articlePart.prosodyPitch,
                    prosodyRate: articlePart.prosodyRate,
                    prosodyVolume: articlePart.prosodyVolume,
                    prosodyContour: articlePart.prosodyContour,
                    emotion: articlePart.emotion,
                    emotionSections: [],
                    replacementTexts: articlePart.replacementTexts || [],
                    pauses: articlePart.pauses || [],
                }

                const audioFileName: string = nanoid();
                const streamingBody: AudioPartStream = {
                    audioName: audioFileName + ".mp3",
                    textSection,
                }

                try {
                    const audio = await api.audio.generateSection(streamingBody);

                    if (audio.status >= 400) {
                        setPlayerState(state => ({
                            ...state,
                            playing: false,
                            isBuffering: false
                        }));

                        if (audio.status === 503) {
                            const body = await audio.json();
                            store.dispatch(showNotPermanentErrorSnackBar(body.error))
                            throw body.error;
                        }
                        else {
                            throw audio.statusText;
                        }
                    }

                    const audioBlob: any = await audio.blob();

                    if (audioBlob?.status >= 400) {
                        const error = await audio?.json();
                        const errorObject: any = new Error(error?.message);
                        errorObject.status = audio?.status;
                        setPlayerState(state => ({
                            ...state,
                            playing: false,
                            isBuffering: false
                        }));
                        throw errorObject;
                    }

                    const audioBlobURL = URL.createObjectURL(audioBlob);
                    handleLoadAudio(setPlayerState, audioBlobURL, true);

                } catch (error) {
                    console.error(error);
                    setPlayerState(state => ({
                        ...state,
                        playing: false,
                        isBuffering: false
                    }));
                }
            })
        }
        else {
            setPlayerState(state => ({
                ...state,
                playing: !state.playing
            }));
        }
    } else {
        setPlayerState(state => ({
            ...state,
            playing: !state.playing
        }));
    }
}

export const handlePlayPauseThesaurus = (
    text: string,
    thesaurusVoiceId: number,
    languageId: number,
    streamState: StreamState,
    setPlayerState: React.Dispatch<React.SetStateAction<PlayerState>>
) => {

    if (streamState.enabled) {
        setPlayerState(state => ({
            ...state,
            url: '',
            playing: true,
            isBuffering: true
        }));

        createStream(streamState).then(async resp => {
            const prosody = {
                pitch: resp.prosodyPitch,
                rate: resp.prosodyRate,
                volume: resp.prosodyVolume,
                contour: resp.prosodyContour
            }

            const voice = {
                id: thesaurusVoiceId,
            }

            const textSection = {
                text,
                prosody,
                languageId,
                emotionSections: [],
                replacementTexts: [],
                pauses: [],
                voice
            }

            const audioFileName: string = nanoid();
            const body = {
                audioName: audioFileName + ".mp3",
                textSection,
            }

            try {
                const audio = await api.audio.generateThesaurusAudio(body);

                if (audio.status >= 400) {
                    setPlayerState(state => ({
                        ...state,
                        playing: false,
                        isBuffering: false
                    }));

                    if (audio.status === 503) {
                        const body = await audio.json();
                        store.dispatch(showNotPermanentErrorSnackBar(body.error))
                        throw body.error;
                    }
                    else {
                        throw audio.statusText;
                    }
                }

                const audioBlob: any = await audio.blob();

                if (audioBlob?.status >= 400) {
                    const error = await audio?.json();
                    const errorObject: any = new Error(error?.message);
                    errorObject.status = audio?.status;
                    setPlayerState(state => ({
                        ...state,
                        playing: false,
                        isBuffering: false
                    }));
                    throw errorObject;
                }

                const audioBlobURL = URL.createObjectURL(audioBlob);
                handleLoadAudio(setPlayerState, audioBlobURL, true);

            } catch (error) {
                console.error(error);
                setPlayerState(state => ({
                    ...state,
                    playing: false,
                    isBuffering: false
                }));
            }
        })
    }
    else {
        setPlayerState(state => ({
            ...state,
            playing: !state.playing
        }));
    }
}

export const handlePlayPauseJingles = (
    jingle: string, streamState: StreamState, setPlayerState: React.Dispatch<React.SetStateAction<PlayerState>>
) => {
    if (jingle !== '') {
        if (streamState.enabled) {
            handleLoadAudio(setPlayerState, jingle, true);
        } else {
            setPlayerState(state => ({
                ...state,
                playing: !state.playing
            }));
        }
    }
}

export const handlePlayPauseBackgrounds = (
    background: string, streamState: StreamState, setPlayerState: React.Dispatch<React.SetStateAction<PlayerState>>
) => {
    if (background !== '') {
        if (streamState.enabled) {
            handleLoadAudio(setPlayerState, background, true);
        } else {
            setPlayerState(state => ({
                ...state,
                playing: !state.playing
            }));
        }
    }
}

export const handlePlay = (setPlayerState: React.Dispatch<React.SetStateAction<PlayerState>>) => {
    setPlayerState(state => ({
        ...state,
        playing: true
    }));
}

export const handlePause = (setPlayerState: React.Dispatch<React.SetStateAction<PlayerState>>) => {
    setPlayerState(state => ({
        ...state,
        playing: false
    }));
}

export const handleSeekMouseDown = (setPlayerState: React.Dispatch<React.SetStateAction<PlayerState>>) => {
    setPlayerState(state => ({
        ...state,
        seeking: true
    }));
}

export const handleSeekMouseUp = (
    seconds: number,
    player: ReactPlayer,
    setPlayerState: React.Dispatch<React.SetStateAction<PlayerState>>
) => {
    setPlayerState(state => (
        {
            ...state,
            seeking: false
        }
    ));
    player.seekTo(seconds);
}

export const handleSeekChange = (
    seconds: number, setPlayerState: React.Dispatch<React.SetStateAction<PlayerState>>
) => {
    setPlayerState(state => ({
        ...state,
        played: seconds
    }));
}

export const handleSeekTo = (seconds: number, player: ReactPlayer, increment = 0) => {
    player.seekTo(seconds + increment);
}

export const handleProgress = (
    playerState: PlayerState,
    setPlayerState: React.Dispatch<React.SetStateAction<PlayerState>>,
    newState: OnProgressProps
) => {
    if (!playerState.seeking) {
        setPlayerState(state => ({
            ...state,
            ...newState
        }));
    }
}

export const handleEnded = (setPlayerState: React.Dispatch<React.SetStateAction<PlayerState>>) => {
    setPlayerState(state => ({
        ...state,
        playing: state.loop,
        isAlreadyPlayed: true
    }));
}

export const handleDuration = (setPlayerState: React.Dispatch<React.SetStateAction<PlayerState>>, duration: number) => {
    setPlayerState(state => ({
        ...state,
        duration
    }));
}

export const handleLoadAudio = (
    setPlayerState: React.Dispatch<React.SetStateAction<PlayerState>>,
    url: string,
    play = false,
    volume = 1
) => {
    setPlayerState(state => ({
        ...state,
        url,
        volume,
        played: 0,
        loaded: 0,
        duration: 0,
        loadAgain: false,
        playing: play
    }))
}

export const handleVolumeChange = (setPlayerState: React.Dispatch<React.SetStateAction<PlayerState>>, volume: number) => {
    setPlayerState(state => ({
        ...state,
        volume: volume < 1 ? volume : 1,
    }))
}
