// state
import { editorInitialParts } from "../../state/editorInitialState";

// helpers
import { extendArticlePart } from "./extendArticle";
import { generatePart } from "./generatePart";

// services
import api from "../services/api";
import { ArticlePart, ArticleSectionType } from "../../interfaces/article/ArticlePart.interface";
import { Voice } from "../../interfaces/article/Voice.interface";
import { Article, ArticleWithContent } from "../../interfaces/article/Article.interface";


export const getArticleParts = async (
    activeArticle: Article,
    editorParts: ArticlePart[],
    voiceList: Voice[]
): Promise<ArticlePart[]> => {
    let parts: ArticlePart[] = [];

    const response = await api.article.get(activeArticle.id);
    const respBody: ArticleWithContent = await response.json();

    if (respBody.parts) parts = [...respBody.parts];
    parts = parts.map(part => ({
        ...part,
        contentHtml: part.contentHtml || part.content
    }))

    if (parts.length === 0) {
        if (editorParts.length > 0) {
            parts = { ...editorParts };
        } else {
            if (voiceList.length > 1) {
                const partContent = 'Error: Could not find article sections';
                parts.push(extendArticlePart(generatePart(0, voiceList[0], partContent, ArticleSectionType.TITLE)));
            } else {
                parts.concat(editorInitialParts);
            }

        }
    }

    return parts;
}
