import React, { useState, useEffect } from 'react';

// modules
import { useDispatch, useSelector } from "react-redux";

// mui
import {
    Box,
    Tab,
    Tabs,
    Typography,
} from '@mui/material';
import Headphones from '@mui/icons-material/Headphones';
import OndemandVideo from '@mui/icons-material/OndemandVideo';
import MenuBook from '@mui/icons-material/MenuBook';
import ListIcon from '@mui/icons-material/List';

// components
import { AudioFilesTab } from './audioFilesTab/AudioFilesTab';
import { VideoFilesTab } from './videoFilesTab/VideoFilesTab';

// actions
import { sidebarTabChange } from '../../../../actions/sidebar.actions';
import { cleanFilesList, loadFiles } from '../../../../actions/files.actions';

// interfaces
import { Selector } from "../../../../interfaces/Selector.interface";
import { ThesaurusTab } from './thesaurusTab/ThesaurusTab';
import { loadPlaylists } from "../../../../actions/playlist.action";
import { PlaylistTab } from "./playlistTab/PlaylistTab";


interface Props {
    children: any,
    index: number,
    value: number
}

const TabPanel = (props: Props) => {
    const { children, value, index } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`sidebar-tabpanel-${index}`}
            key={`sidebar-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
        >
            {
                value === index && (
                    <Box sx={{ p: 1 }}>
                        <Typography component={'div'}>{children}</Typography>
                    </Box>
                )
            }
        </div>
    );
}


export const Sidebar = React.memo(() => {
    const dispatch = useDispatch();

    const audioFilesPublishedDaysOld = useSelector((state: Selector) => state.sidebar.audioFilesPublishedDaysOld);
    const lastArticleListUpdate = useSelector((state: Selector) => state.socket.lastArticleListUpdate);
    const role = useSelector((state: Selector) => state.auth.role);
    const videoAuthorisation = useSelector((state: Selector) => state.auth.videoAuthorisation);

    const [loadingFiles, setLoadingFiles] = useState(false);

    const audioTabIndex = 0;
    const videoTabIndex = videoAuthorisation === true ? 1 : -1;
    const thesaurusTabIndex = role === 'SuperAdmin' ? (videoAuthorisation === true ? 2 : 1) : -1;
    const playlistTabIndex = role === 'SuperAdmin' ? (videoAuthorisation === true ? 3 : 2) : -1;

    const [lastUpdateDate, setLastUpdateDate] = useState("");

    const [value, setValue] = useState(0);

    // default filter date: two days ago
    const [dateFilter, setDateFilter] = useState(
        new Date(
            new Date(
                new Date().getTime() - ((2 * 24) * 60 * 60 * 1000)
            ).setHours(0, 0, 0, 0)
        )
    );



    useEffect(() => {
        if (lastUpdateDate !== lastArticleListUpdate) setLastUpdateDate(lastArticleListUpdate);
        // eslint-disable-next-line
    }, [lastArticleListUpdate])

    // register to article list update according to socket events
    useEffect(() => {
        if (!loadingFiles) setLoadingFiles(true);
        // eslint-disable-next-line
    }, [lastUpdateDate])

    // reset date filter when published days old changed
    useEffect(() => {
        const date = new Date();
        setDateFilter(
            new Date(
                new Date(
                    date.getTime() - ((audioFilesPublishedDaysOld * 24) * 60 * 60 * 1000)
                ).setHours(0, 0, 0, 0)
            )
        );
    }, [audioFilesPublishedDaysOld])

    // reload files on date filter change
    useEffect(() => {
        if (!loadingFiles) setLoadingFiles(true);
        // eslint-disable-next-line
    }, [dateFilter])

    // effect to load files according to state flag
    useEffect(() => {
        if (loadingFiles) {
            cleanFilesList();
            loadFiles(dateFilter).then(action => {
                dispatch(action);
                setLoadingFiles(false);
            })
            loadPlaylists().then(action => dispatch(action));
        }
        // eslint-disable-next-line
    }, [dispatch, loadingFiles]);


    let tabs = [
        {
            index: audioTabIndex,
            name: 'Audio',
            icon: <Headphones />
        }
    ]

    if (videoAuthorisation === true) {
        tabs.push({
            index: videoTabIndex,
            name: 'Video',
            icon: <OndemandVideo />
        })
    }

    if (role === 'SuperAdmin') {
        tabs.push({
            index: thesaurusTabIndex,
            name: 'Lexicon',
            icon: <MenuBook />
        })
        tabs.push({
            index: playlistTabIndex,
            name: 'Playlist',
            icon: <ListIcon />,
        })
    }

    const handleChange = (event: any, newValue: number) => {
        setValue(newValue);
        let tabFilter = tabs.filter(tab => tab.index === newValue);
        if (tabFilter.length > 0) {
            dispatch(sidebarTabChange({
                selectedTabName: tabFilter[0]?.name
            }));
        }
    };


    return (
        <>
            <Box
                key="selector"
                sx={{ borderBottom: 1, borderColor: 'divider' }}
            >
                <Tabs
                    aria-label="sidebar-tabs"
                    data-test="sidebar-tabs"
                    key="sidebar-tabs"
                    onChange={handleChange}
                    value={value}
                    sx={{ display: 'flex', flex: 1 }}
                >
                    {
                        tabs.map(tab => (
                            <Tab
                                aria-label={tab.name}
                                aria-controls={`sidebar-tab-${tab.index}`}
                                data-test={`sidebar-tab-${tab.name.toLowerCase()}`}
                                icon={tab.icon}
                                id={`sidebar-tab-${tab.index}`}
                                key={`sidebar-tab-${tab.index} `}
                                label={tab.name}
                                sx={{
                                    flex: 1, // Each tab takes up equal width
                                    minWidth: "20px"
                                }}
                            />
                        ))
                    }
                </Tabs>
            </Box>

            <TabPanel value={value} index={audioTabIndex} key={'tabPanel0'}>
                <AudioFilesTab />
            </TabPanel>
            {
                videoAuthorisation === true &&
                <TabPanel value={value} index={videoTabIndex} key={'tabPanel1'}>
                    <VideoFilesTab />
                </TabPanel>

            }
            {
                role === 'SuperAdmin' === true &&
                [
                    <TabPanel value={value} index={thesaurusTabIndex} key={'tabPanel2'}>
                        <ThesaurusTab />
                    </TabPanel>,
                    <TabPanel value={value} index={playlistTabIndex} key={'tabPanel3'}>
                        <PlaylistTab />
                    </TabPanel>
                ]
            }
        </>
    );
})
