import React, { useEffect, useState } from "react";

// modules
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';

// mui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';

// components
import { LexiconModal } from "./lexiconModal/LexiconModal";

// interfaces
import { Selector } from "../../../../interfaces/Selector.interface";
import { LexiconEntry } from "../../../../models/lexiconEntry.model";

// styles
import { HeaderCell } from '../../../../theme/styled/HeaderCell';

// actions
import { deleteLexiconEntry, loadConfig } from "../../../../actions/config.actions";
import Flag from "react-world-flags";

// helpers
import { getFlagCode } from "../../../../data/languageLabels";
import { Language } from "../../../../interfaces/article/Language.interface";

interface LexiconRow {
    id: number,
    sourceText: string,
    replacementText: string,
    client: string,
    tts: string,
    languageCode: string,
    isGeneric: boolean,
    visible: boolean
}

const createData = (
    id: number,
    sourceText: string,
    replacementText: string,
    client: string,
    tts: string,
    languageCode: string,
    isGeneric: boolean,
    visible: boolean
) => ({
    id, sourceText, replacementText, client, tts, languageCode, isGeneric, visible
})

export const LexiconTable = React.memo(() => {
    const dispatch = useDispatch();
    const theme = useTheme();

    // state selector
    const config = useSelector((state: Selector) => state.config.config);
    const role = useSelector((state: Selector) => state.auth.role);

    const [sourceTextFilter, setSourceTextFilter] = useState('');
    const [replacementTextFilter, setReplacementTextFilter] = useState('');
    const [scopeFilter, setScopeFilter] = useState('');
    const [languageFilter, setLanguageFilter] = useState('');

    const lexiconStateList = config.filter(c => c.name.toLowerCase() === 'lexicon')[0].data as LexiconEntry[];
    const [lexicon, setLexicon] = useState(lexiconStateList || []);

    const [tableRows, setTableRows] = useState([] as LexiconRow[]);
    const languageList = useSelector((state: Selector) => state.languages.languageList);
    const [languages, setLanguages] = useState<Language[]>([...languageList]);

    // lexicon entry modal
    const [modalState, setModalState] = useState(false);
    const [modalSelectedItem, setModalSelectedItem] = useState(0);
    const handleModalOpen = (index: number) => {
        setModalSelectedItem(index);
        setModalState(true);
    };
    const handleModalClose = () => setModalState(false);

    // effect to update the lexicon list when the state config changed
    useEffect(() => {
        const lexiconState = config.filter(c => c.name.toLowerCase() === 'lexicon')[0].data as LexiconEntry[];
        setLexicon(lexiconState);
        setSourceTextFilter('');
        setReplacementTextFilter('');
    }, [config]);

    useEffect(() => {
        setLanguages([...languageList]);
    }, [languageList])

    // useEffect to create the table rows
    useEffect(() => {
        const rows: LexiconRow[] = [];

        if (lexicon.length > 0) {
            for (let l of lexicon) {
                const client = l.client?.name ? `Custom: ${l.client?.name}` : 'Generic';
                const isGeneric = l.client?.name ? false : true;
                const tts = l.tts || 'All';
                const languageCode = l.language || "";
                rows.push(createData(l.id, l.originalText, l.translationText, client, tts, languageCode, isGeneric, true));
            }

            setTableRows(rows);
        }

    }, [lexicon])


    // function to handle delete an element from lexicon
    const handleDelete = (lexiconEntryId: number) => {
        Swal.fire({
            title: `Are you sure you want to delete the lexicon entry with ID: '${lexiconEntryId}'?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel'
        }).then(result => {
            return new Promise((resolve, reject) => {
                if (result.isConfirmed) {
                    deleteLexiconEntry(lexiconEntryId).then(action => {
                        dispatch(action);
                        loadConfig().then(action => dispatch(action));
                    })

                    resolve('OK');
                }
            })
        })
    }

    const handleFilterSourceTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const filterValue = event.target.value as string;
        setSourceTextFilter(filterValue);
        handleSearch(filterValue, replacementTextFilter, scopeFilter, languageFilter);
    }

    const handleFilterReplacementTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const filterValue = event.target.value as string;
        setReplacementTextFilter(filterValue);
        handleSearch(sourceTextFilter, filterValue, scopeFilter, languageFilter);
    }

    const handleScopeSelectChange = (event: SelectChangeEvent) => {
        const filterValue = event.target.value as string;
        setScopeFilter(filterValue);
        handleSearch(sourceTextFilter, replacementTextFilter, filterValue, languageFilter);
    };

    const handleLanguageSelectChange = (event: SelectChangeEvent) => {
        const filterValue = event.target.value as string;
        setLanguageFilter(filterValue)
        handleSearch(sourceTextFilter, replacementTextFilter, scopeFilter, filterValue);
        }

    const handleSearch = (source: string, replacement: string, scope: string, language: string) => {
        if (source.length === 0 && replacement.length === 0 && scope.length === 0 && language.length === 0) {
            setTableRows(rows => rows.map(r => ({ ...r, visible: true })));
            return;
        }

        let filteredRows: LexiconRow[] = tableRows?.map(r => ({ ...r, visible: true }));

        if (source.length > 0) {
            filteredRows = filteredRows.map(r => ({
                ...r,
                visible: (r.visible && r.sourceText?.toLowerCase().includes(source.toLowerCase())) ? true : false
            }))
        }

        if (replacement.length > 0) {
            filteredRows = filteredRows.map(r => ({
                ...r,
                visible: (r.visible && r.replacementText?.toLowerCase().includes(replacement.toLowerCase())) ? true : false
            }))
        }

        if (language.length > 0) {
            filteredRows = filteredRows.map(r => ({
                ...r,
                visible: (r.visible && r.languageCode?.toLowerCase().includes(language.toLowerCase())) ? true : false
            }))
        }

        if (scope.length > 0) {
            filteredRows = filteredRows.map(r => {
                switch (scope) {
                    case "custom":
                        return { ...r, visible: (r.visible && !r.isGeneric) ? true : false }
                    case "generic":
                        return { ...r, visible: (r.visible && r.isGeneric) ? true : false }
                    default:
                        return { ...r }
                }
            })
        }

        setTableRows(filteredRows);
    }


    return (
        <>
            <Stack
                direction='row'
                spacing={3}
                display='flex'
                justifyContent='center'
                alignItems='center'
                sx={{ mb: 2 }}
            >
                <FilterListIcon htmlColor={theme.palette.secondary.dark} />

                <TextField
                    id="filter-lexicon-source-text-field"
                    label="Source text"
                    variant="outlined"
                    value={sourceTextFilter}
                    onChange={handleFilterSourceTextChange}
                />

                <TextField
                    id="filter-lexicon-replacement-text-field"
                    label="Replacement text"
                    variant="outlined"
                    value={replacementTextFilter}
                    onChange={handleFilterReplacementTextChange}
                />

                <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                        <InputLabel id="filter-lexicon-scope-select-label">Scope</InputLabel>
                        <Select
                            labelId="filter-lexicon-scope-select-label"
                            id="filter-lexicon-scope-select"
                            value={scopeFilter}
                            label="Scope"
                            onChange={handleScopeSelectChange}
                        >
                            <MenuItem value=''>All</MenuItem>
                            <MenuItem value='custom'>Custom</MenuItem>
                            <MenuItem value='generic'>Generic</MenuItem>
                        </Select>
                    </FormControl>
                </Box>

                <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                        <InputLabel id="filter-lexicon-language-select-label">Language</InputLabel>
                        <Select
                            labelId="filter-lexicon-language-select-label"
                            id="filter-lexicon-language-select"
                            value={languageFilter}
                            label="Language"
                            onChange={handleLanguageSelectChange}
                        >
                            <MenuItem value=''>All</MenuItem>
                            {
                                languages?.map(language => 
                                    <MenuItem value={language.code}>
                                        <Flag className="flag-img-lexicon" code={getFlagCode(language.code)} fallback={<span></span>}/>{language.name}
                                    </MenuItem>)
                            }
                        </Select>
                    </FormControl>
                </Box>
            </Stack>

            <TableContainer component={Paper} sx={{ overflowX: "initial" }}>
                <Table
                    aria-label="lexicon-table"
                    stickyHeader
                >
                    <TableHead>
                        <TableRow>
                            <HeaderCell>Source text</HeaderCell>
                            <HeaderCell>Replacement text</HeaderCell>
                            <HeaderCell>Scope</HeaderCell>
                            {role === "SuperAdmin" && <HeaderCell>TTS specific</HeaderCell>}
                            <HeaderCell></HeaderCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableRows?.map(item => item.visible &&
                            <TableRow hover key={`lexicon-${item.id}`}>
                                <TableCell>{item.sourceText}</TableCell>
                                <TableCell>{item.replacementText}</TableCell>
                                <TableCell>{item.client}</TableCell>
                                {role === "SuperAdmin" && <TableCell>{item.tts}</TableCell>}

                                <TableCell>
                                    {
                                        (role === "SuperAdmin" || (role === "Admin" && !item.isGeneric))
                                        &&
                                        <Box key={`lexicon-edit-${item.id}`}>
                                            <Button
                                                key={`edit-${item.id}`}
                                                color="primary"
                                                variant="text"
                                                onClick={() => handleModalOpen(item.id)} >
                                                <EditIcon />
                                            </Button>
                                            <Button
                                                key={`delete-${item.id}`}
                                                color="secondary"
                                                variant="text"
                                                onClick={() => handleDelete(item.id)}>
                                                <DeleteIcon />
                                            </Button>
                                        </Box>
                                    }
                                </TableCell>

                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <Grid
                container
                sx={{
                    bottom: 0,
                    position: 'sticky',
                }}
            >
                <Grid
                    item
                    xs={12}
                    sx={{ mt: 5, textAlign: "center" }}
                >
                    <AddCircleIcon
                        htmlColor={theme.palette.secondary.main}
                        onClick={() => handleModalOpen(0)}
                        sx={{
                            cursor: 'pointer',
                            fontSize: '40px',
                        }}
                    />
                </Grid>
            </Grid>
            <LexiconModal
                modalState={modalState}
                handleModalClose={handleModalClose}
                modalSelectedItem={modalSelectedItem}
            />
        </>
    );
});
